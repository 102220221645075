<template>
	<div style="min-width: 1300px;">
		<a-spin :spinning="false">
			<div>
				<div class="bg-w pd30" style="min-height: 800px;">
					<div>
						  <a-alert message="请在右侧区域进行设置，左侧为展示区。" banner />
					</div>
					
					<div class="mt30">
						<div class="flex center">
							<div>
								<show-mobile :temps="temps"></show-mobile>
							</div>
							
							<div style="margin-left: 75px;">
								<div class="tempalte-operate">
									<div>
										<div class="ft14 ftwblod cl-balck">选择配色方案</div>
										<div class="flex alcenter mt12">
											<span>
												<select-color title="主色调" v-model="temps.main_color"></select-color>
											</span>
											<span class="ml16">
												<select-color title="背景颜色" type="rgba" v-model="temps.home_bg"></select-color>
											</span>
										</div>
									</div>
									<div class="ft14 cl-main mt16">页面组件<span class="ft12 ml10 cl-notice">动态组件模版设置的时候只显示样式不调用真实数据</span></div>
									<div class="mt12">
										<plugins-edit v-model="temps.plugins"></plugins-edit>
									</div>
									<div class="ft14 cl-main mt16">底部导航<span class="ft12 ml10 cl-notice">底部“商城”和“我的”固定不动，可在中间添加修改导航标签，最多添加3个</span></div>
									<div class="mt12">
										<plugins-footers v-model="temps.footers"></plugins-footers>
									</div>
									
									
									<div class="ft14 ftwblod cl-main mt16">模版信息</div>
									<div class="flex alcenter mt12">
										<span style="width: 100px;">模版名称:</span>
										<a-input style="width: 200px;" v-model="name"></a-input>
									</div>
									<div class="flex alcenter mt12">
										<span style="width: 100px;">缩略图:</span>
										<upload-img v-model="photo"></upload-img>
										<span class="ml10">建议尺寸220*360</span>
									</div>
									<div class="mt30">
										<a-button type="primary" :loading="confirmLoading" @click="saveStyle()">保存</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import showMobile from './components/housekeeping/mobile.vue';
	import selectColor from './components/selectColor.vue';
	import  pluginsEdit from './components/housekeeping/plugins.vue';
	import  pluginsFooters from './components/housekeeping/footers.vue';
	import uploadImg from '../../components/upload/upload.vue';
	export default{
		components:{
			showMobile,
			selectColor,
			pluginsEdit,
			pluginsFooters,
			uploadImg
		},
		data(){
			return {
				appId:0,
				templateId:0,
				name:'',
				photo:'',
				loading: false,
				confirmLoading:false,
				temps:{
					main_color:'#00BA26',
					home_bg:'#f7f8f9',
					plugins:[
						
					],
					footers:['appointment','buy'],
				}
			}
		},
		created(){
			if(this.$route.query.appId){
				this.appId = parseInt(this.$route.query.appId);
			}
			if(this.$route.query.templateId){
				this.templateId = parseInt(this.$route.query.templateId);
				this.getDetail();
			}
		},
		methods:{
			getDetail(){
				this.$http.api('platform/admin/getTemplateDetail',{
					templateId:this.templateId,
				}).then(res=>{
					this.appId = res.app_id;
					this.name  = res.name;
					this.photo = res.photo;
					this.temps = res.style;
				}).catch(res=>{
					console.log(res);
				})
			},
			saveStyle(){
				if(this.confirmLoading == true) return;
				this.confirmLoading = true;
				this.$http.api('platform/admin/saveTemplateDetail',{
					appId:this.appId,
					templateId:this.templateId,
					name:this.name,
					photo:this.photo,
					style:JSON.stringify(this.temps)
				}).then(res=>{
					this.confirmLoading = false;
					this.$message.success('操作成功');
					if(this.templateId == 0){
						this.$router.push('/template');
					}
				}).catch(res=>{
					this.confirmLoading = false;
				});
			}
		}
	}
</script>

<style>
	.tempalte-operate{
		width: 750px;
		height: 100%;
		background: #F9F9F9;
		border: 1px solid #F0F3F5;
		padding: 30px;
	}
</style>